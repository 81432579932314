import Cookies from 'js-cookie';
import '../../webpack/components/cookies_consent/cookies_consent.scss';
/* eslint-disable quotes */

document.addEventListener('DOMContentLoaded', () => {
  if (Cookies.get('consent_message')) return;

  const body = document.querySelector('body');
  const container = document.createElement('div');
  container.classList.add('cookies-consent-container');

  const textContainer = document.createElement('div');
  textContainer.classList.add('text-container');

  const text =`We use cookies to help improve your user experience. \
  By using our site, you agree to the terms of our`;

  const span = document.createElement('span');
  span.innerText = text;

  const link = document.createElement('a');
  link.innerText = ' Privacy Policy.';
  link.classList.add('privacy-policy-link');
  link.setAttribute('target', '_blank');
  link.setAttribute('rel', 'noreferrer noopener');
  link.setAttribute('href', 'https://devex.zendesk.com/hc/en-us/articles/115000959453');

  textContainer.appendChild(span);
  textContainer.appendChild(link);

  const closeButton = document.createElement('span');
  closeButton.classList.add('close-consent');
  closeButton.classList.add('fal');
  closeButton.classList.add('icon-times');

  closeButton.addEventListener('click', () => {
    Cookies.set('consent_message', 'consented', { expires: 365 });
    container.classList.add('hide-consent');
  });

  container.appendChild(textContainer);
  container.appendChild(closeButton);

  body.appendChild(container);
});
